import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ContentCopy } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Checkbox, FormLabel, Grid, IconButton, Stack } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import useCoupons from 'api/useCoupons';
import useOffers from 'api/useOffers';
import { Offer, PromoCode } from 'common/contracts';
import { ENotificationType, OfferType } from 'constants/enums';
import dayjs from 'dayjs';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import { useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import * as yup from 'yup';

import AcSelect from 'components/AcSelect/AcSelect';
import ActionButton from 'components/ActionButton/ActionButton';
import DataTable from 'components/DataTable/DataTable';

import AcCard from '../../../components/AcCard/AcCard';
import AcContentWrapper from '../../../components/AcContentWrapper/AcContentWrapper';
import AcInput from '../../../components/AcInput/AcInput';
import AcViewWrapper from '../../../components/AcViewWrapper/AcViewWrapper';
import PageTopBar from '../../../components/Topbar/PageTopBar';
import { AuthSliceState } from '../../../store/store.types';

import '../../../style/forms.scss';

interface ICouponsFormProps {
  edit?: boolean;
  dup?: boolean;
}

export const CouponsForm: React.FC<ICouponsFormProps> = ({
  edit = false,
  dup = false
}) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const navigate = useNavigate();
  const { couponId } = useParams();

  const { getSingleCoupon, addCoupon, updateCoupon } = useCoupons(
    currentPublisherId,
    couponId
  );
  const [neverExpires, setNeverExpires] = useState(false);
  const [firstTimeBuyers, setFirstTimeBuyers] = useState(false);
  const [promoCodes, setPromoCodes] = useState<
    {
      _id: string;
      name: string;
      maxRedemptions: number;
      creationDate: string;
      active: boolean;
    }[]
  >([]);
  const [promoCode, setPromoCode] = useState('');
  const [maxRedemptions, setMaxRedemptions] = useState(0);

  useEffect(() => {
    setPromoCodes(
      (getSingleCoupon.data?.promoCodes as any)?.map((promoCode: any) => ({
        _id: promoCode.id || '',
        name: promoCode.name || '',
        maxRedemptions: promoCode.maxRedemptions || 0,
        active: promoCode.active || false
      }))
    );
  }, [getSingleCoupon.data]);

  const { getOffers } = useOffers(undefined, OfferType.BUNDLE);
  const { getOffers: getSpecialOffers } = useOffers(
    undefined,
    OfferType.SPECIAL_OFFER
  );
  const { getOffers: getPopupOffers } = useOffers(undefined, OfferType.POPUP);
  const { enqueueSnackbar } = useNotifications();

  let couponSchema = yup.object().shape({
    name: yup.string().required('Coupon name is required'),
    active: yup.boolean().required('Coupon status is required'),
    discountPercentage: yup
      .number()
      .min(0, 'Discount percentage must be greater than or equal to 0')
      .max(100, 'Discount percentage must be less than or equal to 100')
      .required('Discount percentage is required'),
    maxRedemptionsPerCustomer: yup
      .number()
      .min(1, 'Max redemptions per customer must be greater than or equal to 1')
      .required('Max redemptions per customer is required'),
    maxRedemptions: yup
      .number()
      .min(0, 'Max redemptions must be greater than or equal to 0')
      .required('Max redemptions is required'),
    currentRedemptions: yup
      .number()
      .min(0, 'Current redemptions must be greater than or equal to 0')
      .required('Current redemptions is required'),
    expiredBy: yup
      .date()
      .nullable()
      .when('neverExpires', {
        is: false,
        then: yup
          .date()
          .required('Expiry date is required when not set to never expire')
      }),
    firstTimePurchase: yup.boolean(),
    supportedOffersByName: yup
      .array()
      .of(yup.string())
      .min(1, 'At least one offer must be selected'),
    supportedOffersByType: yup
      .array()
      .of(yup.string())
      .min(1, 'At least one offer type must be selected'),
    promoCodes: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('Promo code name is required'),
          maxRedemptions: yup
            .number()
            .min(0, 'Max redemptions must be greater than or equal to 0')
            .required('Max redemptions is required')
        })
      )
      .min(1, 'At least one promo code must be added')
  });

  const {
    values,
    handleChange,
    handleBlur,
    submitForm,
    errors,
    touched,
    isValid,
    setFieldValue
  } = useFormik({
    validateOnMount: true,
    validationSchema: couponSchema,
    enableReinitialize: true,
    initialValues: {
      id: getSingleCoupon.data?.id || '',
      name: getSingleCoupon.data?.name || '',
      active: getSingleCoupon.data?.active || true,
      discountPercentage: getSingleCoupon.data?.discountPercentage || 0,
      maxRedemptionsPerCustomer:
        getSingleCoupon.data?.maxRedemptionsPerCustomer || 0,
      maxRedemptions: getSingleCoupon.data?.maxRedemptions || 0,
      currentRedemptions: getSingleCoupon.data?.currentRedemptions || 0,
      expiredBy: getSingleCoupon.data?.expiredBy || '',
      firstTimePurchase: false,
      supportedOffersByName: getSingleCoupon.data?.supportedOffersByName || [],
      supportedOffersByType: getSingleCoupon.data?.supportedOffersByType || [],
      promoCodes: getSingleCoupon.data?.promoCodes || []
    },
    onSubmit: async (values) => {
      let promoCodes = [];

      if (values.maxRedemptions !== undefined) {
        delete (values as any).maxRedemptions;
      }
      if (values.currentRedemptions !== undefined) {
        delete (values as any).currentRedemptions;
      }
      if (values.id !== undefined) {
        delete (values as any).id;
      }

      promoCodes = values.promoCodes.map((promoCode) => {
        console.table(promoCode);
        const promoCodeData = {
          name: promoCode.name,
          maxRedemptions: promoCode.maxRedemptions,
          active: promoCode.active,
          ...(edit ? { id: (promoCode as any)._id } : {})
        };
        if (promoCodeData.id === '') {
          delete promoCodeData.id;
        }
        return promoCodeData;
      });

      const couponData = {
        ...values,
        promoCodes,
        expiredBy: neverExpires ? null : values.expiredBy,
        discountPercentage: Number(values.discountPercentage),
        maxRedemptionsPerCustomer: Number(values.maxRedemptionsPerCustomer)
      };

      if (edit) {
        updateCoupon.mutate(
          {
            ...couponData,
            promoCodes: couponData.promoCodes.map((code) => ({
              ...code
            }))
          },
          {
            onSuccess: (data) => {
              enqueueSnackbar(
                `Coupon updated successfully`,
                ENotificationType.SUCCESS
              );
              navigate(`../`);
            },
            onError: (error) => {
              enqueueSnackbar(`Error updating coupon`, ENotificationType.ERROR);
            }
          }
        );
      } else {
        addCoupon.mutate(
          {
            ...couponData,
            promoCodes: couponData.promoCodes.map((code) => ({
              ...code,
              active: true
            }))
          },
          {
            onSuccess: (data) => {
              enqueueSnackbar(
                `Coupon created successfully`,
                ENotificationType.SUCCESS
              );
              navigate(`../`);
            },
            onError: (error) => {
              enqueueSnackbar(`Error creating coupon`, ENotificationType.ERROR);
            }
          }
        );
      }
    }
  });

  const getOffersForSelect = useMemo(() => {
    const offers = [];
    if (getOffers?.data?.offers && Array.isArray(getOffers.data.offers)) {
      offers.push(
        ...getOffers.data.offers.map((offer: Offer) => ({
          content: offer.name,
          key: offer.publisherOfferId,
          value: offer.publisherOfferId
        }))
      );
    }
    if (
      getSpecialOffers?.data?.offers &&
      Array.isArray(getSpecialOffers.data.offers)
    ) {
      offers.push(
        ...getSpecialOffers.data.offers.map((offer: Offer) => ({
          content: offer.name,
          key: offer.publisherOfferId,
          value: offer.publisherOfferId
        }))
      );
    }
    if (
      getPopupOffers?.data?.offers &&
      Array.isArray(getPopupOffers.data.offers)
    ) {
      offers.push(
        ...getPopupOffers.data.offers.map((offer: Offer) => ({
          content: offer.name,
          key: offer.publisherOfferId,
          value: offer.publisherOfferId
        }))
      );
    }
    return offers;
  }, [getOffers, getSpecialOffers, getPopupOffers]);

  return (
    <AcViewWrapper
      header={
        <PageTopBar
          disable={false}
          headline={`${edit ? 'Edit' : 'New'} Coupon ${
            edit
              ? `(${
                  !getSingleCoupon.isLoading && getSingleCoupon.data
                    ? getSingleCoupon.data.name
                    : '...'
                })`
              : ''
          }`}
          buttons={[
            {
              text: 'Save',
              action: () => submitForm(),
              disabled: !isValid
            }
          ]}
          backFunction={() => navigate(-1)}
        />
      }
    >
      <AcContentWrapper className="formContent">
        <Grid container pt={'24px'}>
          <Grid item xs={12}>
            <Stack>
              <AcCard
                stackContainer={false}
                title="General Settings"
                customStyles={{
                  border: '1px solid #cacbd4',
                  borderRadius: '4px',
                  padding: '1em'
                }}
              >
                <Grid container rowSpacing={2} columnSpacing={1.5}>
                  <Grid item xs={4}>
                    <AcInput
                      header="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      error={Boolean(errors.name) && (touched.name || dup)}
                      helperText={
                        touched.name || dup ? errors.name?.toString() : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AcInput
                      header="Discount"
                      name="discountPercentage"
                      value={values.discountPercentage}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      error={
                        Boolean(errors.discountPercentage) &&
                        touched.discountPercentage
                      }
                      helperText={
                        touched.discountPercentage
                          ? errors.discountPercentage?.toString()
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Stack direction="column">
                      <h3
                        style={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          marginBottom: '4px'
                        }}
                      >
                        <span style={{ color: 'red', marginRight: '6px' }}>
                          *
                        </span>
                        Expires by
                      </h3>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          disablePast
                          format="DD/MM/YYYY HH:mm"
                          value={
                            values.expiredBy ? dayjs(values.expiredBy) : null
                          }
                          onChange={(newValue) => {
                            setFieldValue(
                              'expiredBy',
                              newValue ? newValue.toISOString() : null
                            );
                          }}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: '48.3px'
                            }
                          }}
                          disabled={neverExpires}
                          slotProps={{
                            textField: {
                              error:
                                Boolean(errors.expiredBy) && touched.expiredBy,
                              helperText: touched.expiredBy
                                ? errors.expiredBy?.toString()
                                : ''
                            }
                          }}
                        />
                      </LocalizationProvider>
                      {/* <Stack direction="row" alignItems="center">
                        <Checkbox
                          checked={neverExpires}
                          onChange={(e) => {
                            setNeverExpires(e.target.checked);
                          }}
                          name="active"
                        />
                        <FormLabel
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setNeverExpires(!neverExpires);
                          }}
                        >
                          Never Expires
                        </FormLabel>
                      </Stack> */}
                    </Stack>
                  </Grid>
                </Grid>
              </AcCard>
              <AcCard
                stackContainer={false}
                title="Offers Settings"
                customStyles={{
                  border: '1px solid #cacbd4',
                  borderRadius: '4px',
                  padding: '1em',
                  marginTop: '1em'
                }}
              >
                <Grid container columnSpacing={1.5}>
                  <Grid xs={4} item>
                    <AcSelect
                      header="Eligible offer types"
                      isSelectAllEnabled
                      name="supportedOffersByType"
                      value={values.supportedOffersByType || ['promotions']}
                      required={true}
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      isCheckBox
                      items={[
                        {
                          content: 'Promotions',
                          key: 'promotions',
                          value: 'promotions'
                        },
                        {
                          content: 'Bundles',
                          key: 'bundles',
                          value: 'bundles'
                        },
                        {
                          content: 'Popups',
                          key: 'popups',
                          value: 'popups'
                        }
                      ]}
                      setSelectedItems={(selected: any) => {
                        setFieldValue('supportedOffersByType', selected);
                      }}
                      renderValue={(selected: any) => {
                        return selected
                          .map(
                            (item: string) =>
                              item.charAt(0).toUpperCase() + item.slice(1)
                          )
                          .join(', ');
                      }}
                      error={
                        touched.supportedOffersByName &&
                        Boolean(errors.supportedOffersByName)
                      }
                      helperText={
                        touched.supportedOffersByName
                          ? errors.supportedOffersByName?.toString()
                          : ''
                      }
                    />
                  </Grid>
                  <Grid xs={4} item>
                    <AcSelect
                      header="Offers"
                      name="supportedOffersByName"
                      isSelectAllEnabled
                      value={values.supportedOffersByName}
                      required={true}
                      isCheckBox
                      setSelectedItems={(selected: string[]) => {
                        setFieldValue('supportedOffersByName', selected);
                      }}
                      onChange={(e: any) => {
                        // setCurrentOfferUI(e.target.value as string);
                        handleChange(e);
                      }}
                      renderValue={(selected: any) => {
                        if (selected.length > 2) {
                          return `${selected.length} Offers selected`;
                        }
                        return selected
                          .map(
                            (item: string) =>
                              item.charAt(0).toUpperCase() + item.slice(1)
                          )
                          .join(', ');
                      }}
                      onBlur={handleBlur}
                      items={getOffersForSelect}
                      error={
                        touched.supportedOffersByType &&
                        Boolean(errors.supportedOffersByType)
                      }
                      helperText={
                        touched.supportedOffersByType
                          ? errors.supportedOffersByType?.toString()
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </AcCard>
              <AcCard
                stackContainer={false}
                title="Player Settings"
                customStyles={{
                  border: '1px solid #cacbd4',
                  borderRadius: '4px',
                  padding: '1em',
                  marginTop: '1em'
                }}
              >
                <Grid container rowSpacing={2} columnSpacing={1.5}>
                  <Grid item xs={4}>
                    <Stack direction="column">
                      <AcInput
                        header="Max coupon redemptions per player"
                        name="maxRedemptionsPerCustomer"
                        value={values.maxRedemptionsPerCustomer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        type="number"
                        error={
                          Boolean(errors.maxRedemptionsPerCustomer) &&
                          (touched.maxRedemptionsPerCustomer || dup)
                        }
                        helperText={
                          touched.maxRedemptionsPerCustomer || dup
                            ? errors.maxRedemptionsPerCustomer?.toString()
                            : ''
                        }
                      />
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          checked={firstTimeBuyers}
                          onChange={(e) => {
                            setFirstTimeBuyers(e.target.checked);
                            setFieldValue('firstTimePurchase', firstTimeBuyers);
                          }}
                          name="active"
                        />
                        <FormLabel
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setFirstTimeBuyers(!firstTimeBuyers);
                            setFieldValue('firstTimePurchase', firstTimeBuyers);
                          }}
                        >
                          Limit to first time buyers
                        </FormLabel>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </AcCard>
              <AcCard
                stackContainer={false}
                title="Promo Codes"
                customStyles={{
                  border: '1px solid #cacbd4',
                  borderRadius: '4px',
                  padding: '1em',
                  marginTop: '1em'
                }}
              >
                <Grid container rowSpacing={2} columnSpacing={1.5}>
                  <Grid item xs={4}>
                    <AcInput
                      header="Promo code name"
                      name=""
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AcInput
                      header="Max redemptions"
                      name=""
                      value={maxRedemptions}
                      onChange={(e) =>
                        setMaxRedemptions(Number(e.target.value))
                      }
                      onBlur={handleBlur}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={4} display="flex" alignItems="flex-end">
                    <ActionButton
                      variant="outlined"
                      text="Add New"
                      disabled={!promoCode || maxRedemptions === 0}
                      style={{
                        height: '48px'
                      }}
                      onClick={() => {
                        const newPromoCodes = [
                          {
                            _id: '',
                            active: true,
                            name: promoCode,
                            maxRedemptions: maxRedemptions,
                            creationDate: new Date().toISOString()
                          },
                          ...values.promoCodes
                        ];
                        setFieldValue('promoCodes', newPromoCodes);
                        setPromoCode('');
                        setMaxRedemptions(0);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DataTable
                      columns={[
                        {
                          headerName: 'Name',
                          field: 'name',
                          flex: 1,
                          renderCell: (params) => {
                            return (
                              <Stack direction="row" alignItems="center">
                                <span>{params.value}</span>
                                <IconButton
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      params.row.name
                                    );
                                    enqueueSnackbar(
                                      `Promo code copied`,
                                      ENotificationType.SUCCESS
                                    );
                                  }}
                                >
                                  <ContentCopy />
                                </IconButton>
                              </Stack>
                            );
                          }
                        },
                        {
                          headerName: 'Max Redemptions',
                          field: 'maxRedemptions',
                          flex: 1
                        },
                        {
                          headerName: 'Creation Date',
                          field: 'creationDate',
                          flex: 1,
                          renderCell: () => {
                            return 'N/A';
                          }
                        },
                        {
                          headerName: 'Status',
                          field: 'active',
                          flex: 1,
                          renderCell: (params) => {
                            return (
                              <StatusLabel
                                text={!!params.value ? 'Active' : 'Inactive'}
                                status={
                                  !!params.value
                                    ? EStatusLabel.SUCCESS
                                    : EStatusLabel.FAILED
                                }
                              />
                            );
                          }
                        },

                        {
                          field: 'actions',
                          flex: 1,
                          type: 'actions',
                          width: 80,
                          disableReorder: true,

                          getActions: (params: any) => [
                            <GridActionsCellItem
                              icon={<CheckCircleOutlineIcon />}
                              label={
                                params.row.active ? 'Deactivate' : 'Activate'
                              }
                              onClick={() => {
                                const newPromoCodes = values.promoCodes.map(
                                  (promoCode: any) => {
                                    if (promoCode._id === params.row._id) {
                                      return {
                                        ...promoCode,
                                        active: !promoCode.active
                                      };
                                    }
                                    return promoCode;
                                  }
                                );
                                setFieldValue('promoCodes', newPromoCodes);
                              }}
                              showInMenu
                            />
                          ]
                        }
                      ]}
                      rowIdIdentifier="_id"
                      rows={values.promoCodes || []}
                      loading={false}
                    />
                  </Grid>
                </Grid>
              </AcCard>
            </Stack>
          </Grid>
        </Grid>
      </AcContentWrapper>
    </AcViewWrapper>
  );
};
