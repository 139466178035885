import { useMutation, useQuery } from '@tanstack/react-query';

import { Coupon, User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import {
  ApiRoutes,
  EApiRoutes,
  fetchConfig,
  getApiRouteOrMock
} from './api.utils';

export interface PromoCodeDetailsData {
  name: string;
  maxRedemptions: number;
  active: boolean;
}

export interface CouponDetailsData {
  name: string;
  active: boolean;
  discountPercentage: number;
  maxRedemptionsPerCustomer: number;
  expiredBy: string | null;
  supportedOffersByName: string[];
  supportedOffersByType: string[];
  firstTimePurchase: boolean;
  promoCodes: PromoCodeDetailsData[];
}

export default function useCoupons(
  currentPublisherId?: string | null,
  couponId?: string | null
) {
  const axios = useAxios();
  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;

  const getCoupons = useQuery({
    ...fetchConfig.general,
    queryKey: [EQueryKeys.COUPONS, currentPublisherId],
    queryFn: async () => {
      console.log('getCoupons', currentPublisherId);
      const coupons = await axios.get(
        getApiRouteOrMock(EApiRoutes.COUPONS),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      return coupons as Coupon[];
    },
    enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
  });

  const getSingleCoupon = useQuery({
    queryKey: [EQueryKeys.SINGLE_COUPON, couponId],
    queryFn: async () => {
      console.log('getSingleCoupon', couponId);
      const coupon = await axios.get(
        `${ApiRoutes.COUPONS}/${couponId}`,
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      return coupon as Coupon;
    },
    enabled: !!couponId && !!currentPublisherId
  } as const);

  const updateCoupon = useMutation(async (couponData: any) => {
    const response = await axios.put(
      `${ApiRoutes.COUPONS}/${couponId}`,
      couponData,
      currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
    );
    return response.data;
  });

  const setCouponActive = useMutation(
    async ({ couponId, active }: { couponId: string; active: boolean }) => {
      const response = await axios.put(
        `${ApiRoutes.COUPONS}/${couponId}/setActivation`,
        { active },
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      return response.data;
    }
  );

  const addCoupon = useMutation(
    async (couponData: Partial<CouponDetailsData>) => {
      const response = await axios.post(
        getApiRouteOrMock(EApiRoutes.COUPONS),
        couponData,
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      return response.data;
    }
  );

  return {
    getCoupons,
    getSingleCoupon,
    updateCoupon,
    addCoupon,
    setCouponActive
  };
}
