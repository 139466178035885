import { useEffect, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Divider, Stack } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {
  ExtendedAppearanceStatus,
  ExtendedDateRange,
  ScheduleTimeFrame,
  TimeFrameTableData
} from 'common/contracts';
import { DATE_TIME_FORMAT_UTC } from 'constants/constants';
import {
  EAppearanceStatus,
  ELocalStorageKeys,
  ENotificationType,
  EPendingAppearanceStatus,
  EScheduleType
} from 'constants/enums';
import dayjs from 'dayjs';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import { useNotifications } from 'hooks/useNotifications';

import AcCard from 'components/AcCard/AcCard';
import AcDateTimePicker from 'components/AcDateTimePicker/AcDateTimePicker';
import AcSwitchSelector from 'components/AcSwitchSelector/AcSwitchSelector';
import DataTable from 'components/DataTable/DataTable';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

const ScheduleTab = ({
  values,
  formikProps,
  setActive,
  updateOffer,
  edit
}: any) => {
  const { setFieldValue } = formikProps;

  const [statusValue, setStatusValue] = useState<string[]>([]);
  const [appearancesTableData, setAppearancesTableData] = useState<
    TimeFrameTableData[]
  >([]);
  const [editRangeDatesValues, setEditRangeDatesValues] =
    useState<ExtendedDateRange | null>(null);
  const [notes, setNotes] = useState('');

  const { enqueueSnackbar } = useNotifications();

  useEffect(() => {
    const timeFramesData: TimeFrameTableData[] =
      values.schedule?.timeFrames?.map(
        (date: ScheduleTimeFrame, index: number) => ({
          id: date.id || `id-${index}`,
          startTime: date.startTime,
          endTime: date.endTime,
          notes: date.notes,
          status: statusValue
        })
      );

    setAppearancesTableData(timeFramesData);
  }, [values?.schedule?.timeFrames, statusValue]);

  useEffect(() => {
    setActive(values.active);
  }, [values.active]);

  const updateOfferStatus = async (active: boolean) => {
    if (edit) {
      try {
        await updateOffer.mutateAsync({
          offerId: String(values?.offerId),
          form: { active: active }
        });
        setFieldValue('active', active);
      } catch (error) {
        enqueueSnackbar('Error editing special offer', ENotificationType.ERROR);
      }
    } else setFieldValue('active', active);
  };

  // Helper function to determine the status based on current date and time frame
  const determineStatus = (
    startTime: Date,
    endTime: Date
  ): EAppearanceStatus => {
    const currentDate = new Date();

    if (currentDate >= startTime && currentDate <= endTime) {
      return EAppearanceStatus.RUNNING;
    } else if (currentDate < startTime) {
      return EAppearanceStatus.UPCOMING;
    } else {
      return EAppearanceStatus.ENDED;
    }
  };

  const isOverlapping = (newStartTime: any, newEndTime: any) => {
    return values?.schedule?.timeFrames.some((timeFrame: any) => {
      const existingStart = new Date(timeFrame.startTime).getTime();
      const existingEnd = new Date(timeFrame.endTime).getTime();

      return (
        (newStartTime >= existingStart && newStartTime <= existingEnd) || // New start time is within an existing range
        (newEndTime >= existingStart && newEndTime <= existingEnd) || // New end time is within an existing range
        (newStartTime <= existingStart && newEndTime >= existingEnd) // New range fully overlaps an existing range
      );
    });
  };

  const handleApply = (range: any, notes?: string) => {
    const [fromUtcDate, toUtcDate] = range;

    const newTimeFrame: ScheduleTimeFrame = {
      startTime: new Date(fromUtcDate), // UTC start date
      endTime: new Date(toUtcDate), // UTC end date
      notes
    };

    if (editRangeDatesValues) {
      // Update the existing time frame that matches editData
      const [editStartTime, editEndTime] = editRangeDatesValues;

      const updatedTimeFrames = values?.schedule?.timeFrames.map(
        (timeFrame: ScheduleTimeFrame) => {
          const currentStartTimestamp = new Date(timeFrame.startTime).getTime();
          const currentEndTimestamp = new Date(timeFrame.endTime).getTime();

          // Replace the old time frame if timestamps match, otherwise keep it unchanged
          return currentStartTimestamp === new Date(editStartTime).getTime() &&
            currentEndTimestamp === new Date(editEndTime).getTime()
            ? newTimeFrame
            : timeFrame;
        }
      );
      setFieldValue('schedule', {
        ...values.schedule,
        timeFrames: updatedTimeFrames
      });
    } else {
      setEditRangeDatesValues(null);
      setFieldValue('schedule', {
        ...values.schedule,
        timeFrames: [...values?.schedule?.timeFrames, newTimeFrame]
      });
    }
  };

  const options = [
    { value: EScheduleType.PERMANENT },
    { value: EScheduleType.SCHEDULED }
  ];

  const columns = [
    {
      field: 'startTime',
      headerName: 'Start',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <span>
            {dayjs(params.row.startTime).utc().format(DATE_TIME_FORMAT_UTC)}
          </span>
        );
      }
    },
    {
      field: 'endTime',
      headerName: 'End',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <span>
            {dayjs(params.row.endTime).utc().format(DATE_TIME_FORMAT_UTC)}
          </span>
        );
      }
    },
    {
      field: 'notes',
      headerName: 'Notes',
      disableReorder: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (row: any) => {
        const { startTime, endTime, id } = row.row;
        const pendingSaveStatus = id.startsWith('id-');
        const status: ExtendedAppearanceStatus = pendingSaveStatus
          ? EPendingAppearanceStatus.PENDING_SAVE
          : determineStatus(new Date(startTime), new Date(endTime));
        return (
          <StatusLabel
            text={status}
            status={
              status === EAppearanceStatus.RUNNING
                ? EStatusLabel.ACTIVE
                : status === EAppearanceStatus.UPCOMING
                  ? EStatusLabel.PENDING
                  : pendingSaveStatus
                    ? EStatusLabel.CANCELED
                    : EStatusLabel.FAILED
            }
            prefixIcon={
              status === EAppearanceStatus.RUNNING ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ScheduleIcon />
              )
            }
          />
        );
      },
      sortComparator: (a: any, b: any, params1: any, params2: any) => {
        const rowA = appearancesTableData.find((row) => row.id === params1.id);
        const rowB = appearancesTableData.find((row) => row.id === params2.id);

        if (!rowA || !rowB) return 0;

        const startTimeA = rowA.startTime
          ? new Date(rowA.startTime)
          : new Date();
        const endTimeA = rowA.endTime ? new Date(rowA.endTime) : new Date();
        const startTimeB = rowB.startTime
          ? new Date(rowB.startTime)
          : new Date();
        const endTimeB = rowB.endTime ? new Date(rowB.endTime) : new Date();

        const statusA = determineStatus(startTimeA, endTimeA);
        const statusB = determineStatus(startTimeB, endTimeB);

        const statusOrder = {
          [EAppearanceStatus.RUNNING]: 1,
          [EAppearanceStatus.UPCOMING]: 2,
          [EAppearanceStatus.ENDED]: 3
        };

        return (statusOrder[statusA] || 0) - (statusOrder[statusB] || 0);
      }
    },
    {
      field: 'actions',
      type: 'actions',
      disableReorder: true,
      width: 50,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          showInMenu
          onClick={() => {
            const { startTime, endTime, notes } = params.row;
            setEditRangeDatesValues([
              new Date(startTime),
              new Date(endTime),
              notes
            ]);
          }}
        />,
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          showInMenu
          onClick={() => {
            const { startTime, endTime } = params.row;
            const newTimeFrames = values?.schedule?.timeFrames?.filter(
              (timeFrame: ScheduleTimeFrame) => {
                return (
                  new Date(timeFrame.startTime).getTime() !==
                    new Date(startTime).getTime() &&
                  new Date(timeFrame.endTime).getTime() !==
                    new Date(endTime).getTime()
                );
              }
            );
            setEditRangeDatesValues(null);
            setNotes('');
            if (!newTimeFrames.length) {
              setFieldValue('schedule', {
                permanent: true,
                timeFrames: newTimeFrames
              });
            } else {
              setFieldValue('schedule', {
                ...values.schedule,
                timeFrames: newTimeFrames
              });
            }
          }}
        />
      ]
    }
  ];

  const onClickSwitch = (value: any) => {
    const permanent = value === EScheduleType.PERMANENT;
    setFieldValue('schedule', {
      ...values.schedule,
      permanent: permanent
    });
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <AcSwitchSelector
          options={options}
          onClick={onClickSwitch}
          value={values?.schedule?.permanent}
        />
        <CustomizedSwitch
          status={values.active}
          texts={['Is Active', 'Is Active']}
          functions={[
            () => updateOfferStatus(false),
            () => updateOfferStatus(true)
          ]}
          color={'#000000'}
        />
      </Stack>
      <Stack
        spacing={2}
        sx={{
          opacity: values?.schedule?.permanent ? 0.5 : 1,
          pointerEvents: values?.schedule?.permanent ? 'none' : 'auto',
          marginTop: '2rem'
        }}
      >
        <AcCard
          stackContainer={false}
          title={
            editRangeDatesValues ? 'Edit appearance' : 'Schedule new appearance'
          }
        >
          <AcDateTimePicker
            onApply={(v: any, notes?: string) => {
              handleApply(v, notes);
            }}
            editRangeDatesValues={editRangeDatesValues}
            setEditRangeDatesValues={setEditRangeDatesValues}
            disableFuture={false}
            minDate={dayjs().utc().startOf('day')}
            applyText={editRangeDatesValues ? 'Apply' : 'Add Appearance'}
            isOverlapping={isOverlapping}
            notes={notes}
            setNotes={setNotes}
          />
        </AcCard>
        <Divider />
        <DataTable
          columns={columns}
          rows={appearancesTableData}
          loading={false}
          rowIdIdentifier="id"
          defaultHiddenFields={['id']}
          localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
          hideFooter
          error={false}
          initialSorting={{
            sortModel: [{ field: 'createdAt', sort: 'desc' }]
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ScheduleTab;
