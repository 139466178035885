import {
  ApplovinBundle,
  BagelcodeBundle,
  CandivoreBundle,
  CashmanCasinoBundle,
  ClassicBundle,
  CommunixBundle,
  HeartOfVegasBundle,
  JackpotSlotsBundle,
  LightningLinkBundle,
  LocoBingoBundle,
  MainSubBundle,
  Me2onBundle,
  PipaBundle,
  PlaySimpleBundle,
  WhispersBundle
} from '@appcharge/shared-ui';

import { EBundlesInternalViewModel } from '../constants/enums';

export const mapBundleComponents: Record<EBundlesInternalViewModel, any> = {
  [EBundlesInternalViewModel.AK]: ClassicBundle,
  [EBundlesInternalViewModel.ME2ON]: Me2onBundle,
  [EBundlesInternalViewModel.MAIN_SUB]: MainSubBundle,
  [EBundlesInternalViewModel.MAIN_SUB_ALT]: ApplovinBundle,
  [EBundlesInternalViewModel.COMMUNIX]: CommunixBundle,
  [EBundlesInternalViewModel.MATCH]: CandivoreBundle,
  [EBundlesInternalViewModel.BAGELCODE]: BagelcodeBundle,
  [EBundlesInternalViewModel.LOCO_BINGO]: LocoBingoBundle,
  [EBundlesInternalViewModel.JACKPOT_SLOTS]: JackpotSlotsBundle,
  [EBundlesInternalViewModel.BIG_FISH_CASINO]: JackpotSlotsBundle,
  [EBundlesInternalViewModel.CASHMAN_CASINO]: CashmanCasinoBundle,
  [EBundlesInternalViewModel.LIGHTNING_LINK_CASINO]: LightningLinkBundle,
  [EBundlesInternalViewModel.HEART_OF_VEGAS]: HeartOfVegasBundle,
  [EBundlesInternalViewModel.PLAY_SIMPLE]: PlaySimpleBundle,
  [EBundlesInternalViewModel.PIPA]: PipaBundle,
  [EBundlesInternalViewModel.WHISPERS]: WhispersBundle
};
