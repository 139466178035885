import React, { useMemo, useState } from 'react';

import ReactCountryFlag from 'react-country-flag';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import useLocalization from 'api/useLocalization';
import { EButtonVariant } from 'constants/enums';
import { useFormik } from 'formik';
import { handleRenderCountriesList } from 'views/Localization/utils';

import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import ActionButton from 'components/ActionButton/ActionButton';

import { ManageLanguageFormProps } from './ManageLanguageForm.types';
import { addNewLanguageSchema } from './validation-schema';

import './style.scss';

const ManageLanguageForm = ({
  countriesList,
  selectedLanguage,
  onSave,
  btnVariant = EButtonVariant.OUTLINED,
  btnText = 'Add New'
}: ManageLanguageFormProps) => {
  const { getAddedLanguagesList } = useLocalization();

  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    selectedLanguage?.country ?? []
  );

  const countriesOptions = useMemo(() => {
    return [
      ...Object.entries(countriesList).map(([key, value]) => ({
        content: value,
        key: key,
        value: value,
        renderFunction: () => (
          <Stack
            className="manageLanguageForm__countryItem"
            direction="row"
            alignItems="center"
            gap={1}
          >
            <ReactCountryFlag
              className="manageLanguageForm__countryFlag"
              countryCode={key}
              svg
            />
            <Typography className="manageLanguageForm__countryName">{value}</Typography>
          </Stack>
        )
      }))
    ];
  }, [countriesList]);

  const handleSelectCountry = (arrSelectedCountries: string[]) => {
    const newSelectedValues = arrSelectedCountries.filter(
      (item: string) => item !== ''
    );
    setFieldValue('country', newSelectedValues, true);
    setSelectedCountries(newSelectedValues);
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    isValid,
    setFieldValue
  } = useFormik({
    initialValues: {
      language: selectedLanguage?.language ?? '',
      country: selectedLanguage?.country ?? []
    },
    validationSchema: addNewLanguageSchema,
    onSubmit: (values, { resetForm }) => {
      const parsedLanguageData = values.country.map((country) => ({
        language: values.language,
        country:
          (countriesOptions.find((option) => option.value === country)
            ?.key as string) ?? ''
      }));

      if (!selectedLanguage) {
        const newLanguagesList = getAddedLanguagesList?.data
          ? [...getAddedLanguagesList.data, ...parsedLanguageData]
          : parsedLanguageData;
        onSave(newLanguagesList);
      } else {
        onSave(parsedLanguageData);
      }
      setSelectedCountries([]);
      resetForm();
    }
  });

  return (
    <form className="manageLanguageForm" onSubmit={handleSubmit}>
      <Stack
        direction="row"
        alignItems="flex-start"
        className="manageLanguageFormContainer"
      >
        <AcInput
          header="Language Display Name"
          name="language"
          value={values.language}
          onChange={handleChange}
          onBlur={handleBlur}
          customClass="manageLanguageForm__input"
          error={touched.language && !!errors.language}
          helperText={(touched.language && errors.language) || ''}
        />
        <AcSelect
          name="country"
          header="Country"
          value={values.country}
          setSelectedItems={(values: string[]) => handleSelectCountry(values)}
          items={countriesOptions}
          isApplyBtnEnabled={false}
          renderValue={() => handleRenderCountriesList(selectedCountries)}
          className="manageLanguageForm__countrySelect"
          error={!!errors.country}
          helperText={(errors.country as string) || ''}
          isCheckBox
          isSearchEnabled
        />
        <ActionButton
          variant={btnVariant}
          text={btnText}
          onClick={submitForm}
          disabled={!isValid}
        />
      </Stack>
    </form>
  );
};

export default ManageLanguageForm;
